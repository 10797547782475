import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AudioCompare from "./routes/AudioCompare/AudioCompare";
import EffectPlayer from "./routes/EffectPlayer/EffectPlayer";
import PEQPlayer from "./routes/PEQPlayer/PEQPlayer";
import StepSequencer from "./routes/StepSequencer/StepSequencer";
import MidiPlayer from "./routes/MidiPlayer/MidiPlayer";
import MediaPlayer from "./routes/MediaPlayer/MediaPlayer";
import Compressor from "./routes/Compressor/Compressor";
import CompressorPlayerNew from "./routes/Compressor/CompressorNew";

import "react-toastify/dist/ReactToastify.css";
import "./font.css";
import "./App.css";

function Root() {
  // const mediaSource = 'https://www.youtube.com/watch?v=5kNNMVbcLlE'; // Replace with your actual media source

  return (
    <div className="flex flex-col justify-center items-center h-screen gap-1">
      <p className="mb-5 font-bold text-xl">Examples for testing only</p>
      <div className="grid grid-cols-3">
        <div className="flex flex-col items-center">
          <h2>Audio Compare with id 59</h2>
          <p className="mb-3">
            <a
              href="/audio-compare/59"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /audio-compare/59
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Effect Player Compare with type delay</h2>
          <p className="mb-3">
            <a
              href="/effect-player/delay"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /effect-player/delay
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Delay</h2>
          <p className="mb-3">
            <a
              href="/delay/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /delay/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Compressor</h2>
          <p className="mb-3">
            <a
              href="/compressor/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /compressor/1
            </a>
          </p>
        </div>

        <div className="flex flex-col items-center">
          <h2>gate</h2>
          <p className="mb-3">
            <a
              href="/gate/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /gate/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Limiter</h2>
          <p className="mb-3">
            <a
              href="/limiter/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /limiter/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Multiband Compressor</h2>
          <p className="mb-3">
            <a
              href="/multibandcompressor/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /multibandcompressor/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Mid Side Compressor</h2>
          <p className="mb-3">
            <a
              href="/midsidecompressor/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /midsidecompressor/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Distortion</h2>
          <p className="mb-3">
            <a
              href="/distortion/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /distortion/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Bitcrusher</h2>
          <p className="mb-3">
            <a
              href="/bitcrusher/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /bitcrusher/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Reverb</h2>
          <p className="mb-3">
            <a
              href="/reverb/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /reverb/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Quadrafuzz</h2>
          <p className="mb-3">
            <a
              href="/quadrafuzz/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /quadrafuzz/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Flanger</h2>
          <p className="mb-3">
            <a
              href="/flanger/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /flanger/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>phaser</h2>
          <p className="mb-3">
            <a
              href="/phaser/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /phaser/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>pitchShift</h2>
          <p className="mb-3">
            <a
              href="/pitchShift/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /pitchShift/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>stereowidener</h2>
          <p className="mb-3">
            <a
              href="/stereowidener/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /stereowidener/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>PEQ Player with id 1</h2>
          <p className="mb-3">
            <a
              href="/peq-player/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /peq-player/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Step Sequencer</h2>
          <p className="mb-3">
            <a
              href="/step-sequencer/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /step-sequencer/1
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Midi Player</h2>
          <p className="mb-3">
            <a
              href="/midi-player"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /midi-player
            </a>
          </p>
        </div>

        <div className="flex flex-col items-center">
          <h2>Media Player</h2>
          <p className="mb-3">
            <a
              href="/MediaPlayer/Afloat_Beat.wav"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /MediaPlayer/Afloat_Beat.wav
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <h2>Compressor New</h2>
          <p className="mb-3">
            <a
              href="/compressor-new/1"
              className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white"
            >
              /compressor-new/1
            </a>
          </p>
        </div>
      </div>
      <p className="mt-8">V0.1.7 Beta 202401011 ()</p>
    </div>
  );
}

function App() {
  const router = createBrowserRouter([
    {
      path: "/midi-player",
      element: <MidiPlayer />,
    },
    {
      path: "/step-sequencer/:id",
      element: <StepSequencer />,
    },
    {
      path: "/peq-player/:id",
      element: <PEQPlayer />,
    },
    {
      path: "/effect-player/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/effect-player",
      element: <Root />,
    },
    {
      path: "/audio-compare/:id",
      element: <AudioCompare />,
    },
    {
      path: "/audio-compare",
      element: <Root />,
    },
    {
      path: "/MediaPlayer/:name",
      element: <MediaPlayer />,
    },
    {
      path: "",
      element: <Root />,
    },
    {
      path: "/delay/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/compressor/:id",
      element: <CompressorPlayerNew />,
    },
    {
      path: "/compressor-new/:id",
      element: <Compressor />,
    },
    {
      path: "/gate/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/limiter/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/multibandcompressor/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/midsidecompressor/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/distortion/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/bitcrusher/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/reverb/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/quadrafuzz/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/flanger/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/phaser/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/pitchshift/:id",
      element: <EffectPlayer />,
    },
    {
      path: "/stereowidener/:id",
      element: <EffectPlayer />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
