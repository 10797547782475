import { useState, useRef, useEffect } from "react";
import { AudioContext } from "standardized-audio-context";
import throttle from 'lodash/throttle';
import * as Tone from "tone";
import Dial from "../Dial/Dial";

export default function EffectLayer({
  effectType,
  effectConfig,
  audioRef,
  mediaElementSourceRef,
  audioContextRef,
  isPlaying,
  turnOnSwitch,
  reloadEffect,
  updateConfig,
}) {
  const [effectValueConfig, setEffectValueConfig] = useState({});

  const effectRef = useRef(null);

  const handleRangeChange = (effect, value) => {
    setEffectValueConfig((prev) => ({
      ...prev,
      [effect]: Number(value),
    }));
    updateConfig((prev) => ({
      ...prev,
      [effect]: {
        min: prev[effect].min,
        max: prev[effect].max,
        step: prev[effect].step,
        value: Number(value),
      },
    }));
    updateEffect();
  };

  const loadEffect = () => {
    if (reloadEffect) {
      if (!audioContextRef.current) {
        audioContextRef.current = new AudioContext();
      }
      const audioContext = audioContextRef.current;

      if (!mediaElementSourceRef.current && audioRef.current) {
        mediaElementSourceRef.current = audioContext.createMediaElementSource(
          audioRef.current
        );
      }
      const sourceNode = mediaElementSourceRef.current;

      // Set the context used by Tone.js after disposing its default context
	  Tone.getContext().dispose();
      Tone.setContext(audioContext);

      // Fetch effect Tone
      switch (effectType) {
        case "delay":
          // Create a FeedbackDelay effect
          console.log(effectValueConfig);
          effectRef.current = new Tone.FeedbackDelay({
            delayTime: effectValueConfig.Time,
            wet: effectValueConfig.Wet,
            feedback: effectValueConfig.Feedback,
          }).toDestination();
          break;
        case "pingPongDelay":
          // Create a pingPongDelay effect
          effectRef.current = new Tone.PingPongDelay({
            delayTime: effectValueConfig.Time,
            wet: effectValueConfig.Wet,
            feedback: effectValueConfig.Feedback,
          }).toDestination();
          break;
        case "distortion":
          // Create a distortion effect
          effectRef.current = new Tone.Distortion({
            distortion: effectValueConfig.Distortion,
          }).toDestination();
          break;
        case "reverb":
          // Create a reverb effect
          effectRef.current = new Tone.Reverb({
            decay: effectValueConfig.decay,
            preDelay: effectValueConfig.preDelay,
            wet: effectValueConfig.Wet,
          }).toDestination();
          break;
        case "flanger":
          // Create a flanger effect
          effectRef.current = new Tone.Chorus({
            frequency: effectValueConfig.Frequency,
            delayTime: effectValueConfig.Time,
            depth: effectValueConfig.Depth,
            feedback: effectValueConfig.Feedback,
            wet: effectValueConfig.Wet,
          }).toDestination();

          break;
        case "quadrafuzz":
          effectRef.current = createQuadrafuzz(
            effectValueConfig.lowGain,
            effectValueConfig.midLowGain,
            effectValueConfig.midHighGain,
            effectValueConfig.highGain
          );
          break;
        case "dubdelay":
          const filter = new Tone.Filter({
            frequency: effectValueConfig.Cutoff,
            type: "lowpass",
          }).toDestination();

          const delay = new Tone.FeedbackDelay({
            delayTime: effectValueConfig.Time,
            wet: effectValueConfig.Wet,
            feedback: effectValueConfig.Feedback,
            maxDelay: 1.0,
          }).connect(filter);

          effectRef.current = delay;
          break;
        case "compressor":
          // Create a Compressor effect with specified parameters
          effectRef.current = new Tone.Compressor({
            threshold: effectValueConfig.Threshold,
            ratio: effectValueConfig.Ratio,
            attack: effectValueConfig.Attack,
            release: effectValueConfig.Release,
            knee: effectValueConfig.Knee,
          }).toDestination();
          break;
        case "gate":
          // Create a Gate effect with specified parameters
          effectRef.current = new Tone.Gate({
            threshold: effectValueConfig.Threshold,
            smoothing: effectValueConfig.Smoothing,
          }).toDestination();
          break;
        case "multibandcompressor":
          // Create a multiBandCompressor effect with specified parameters
          effectRef.current = new Tone.MultibandCompressor({
            low: {
              threshold: effectValueConfig.lowThreshold,
              ratio: effectValueConfig.lowRatio,
              attack: effectValueConfig.lowAttack,
              release: effectValueConfig.lowRelease,
            },
            mid: {
              threshold: effectValueConfig.midThreshold,
              ratio: effectValueConfig.midRatio,
              attack: effectValueConfig.midAttack,
              release: effectValueConfig.midRelease,
            },
            high: {
              threshold: effectValueConfig.highThreshold,
              ratio: effectValueConfig.highRatio,
              attack: effectValueConfig.highAttack,
              release: effectValueConfig.highRelease,
            },
            lowFrequency: effectValueConfig.lowFrequency,
            highFrequency: effectValueConfig.highFrequency,
          }).toDestination();
          break;
        case "limiter":
          // Create a limiter effect with specified parameters
          effectRef.current = new Tone.Limiter({
            threshold: effectValueConfig.Threshold,
          }).toDestination();
		  console.log(effectRef.current);
          break;
        case "bitcrusher":
          effectRef.current = new Tone.BitCrusher({
            bits: effectValueConfig.bits,
            wet: effectValueConfig.wet,
          }).toDestination();
          break;
        case "phaser":
          // Create a Phaser effect with specified parameters
          effectRef.current = new Tone.Phaser({
            frequency: effectValueConfig.Frequency,
            octaves: effectValueConfig.Octaves,
            stages: effectValueConfig.Stages,
            Q: effectValueConfig.Q,
            baseFrequency: effectValueConfig.BaseFrequency,
          }).toDestination();
          break;
        case "pitchShift":
          // Create a PitchShift effect
          effectRef.current = new Tone.PitchShift({
            pitch: effectValueConfig.Pitch,
            windowSize: effectValueConfig.WindowSize,
            delayTime: effectValueConfig.DelayTime,
            feedback: effectValueConfig.Feedback,
          }).toDestination();
          break;
        case "stereowidener":
          effectRef.current = new Tone.StereoWidener({
            wet: effectValueConfig.Wet,
            width: effectValueConfig.Width,
          }).toDestination();
          break;
        case "midsidecompressor":
          effectRef.current = new Tone.MidSideCompressor({
            mid: {
              threshold: effectValueConfig.midThreshold,
              ratio: effectValueConfig.midRatio,
              attack: effectValueConfig.midAttack,
              release: effectValueConfig.midRelease,
              knee: effectValueConfig.midKnee,
            },
            side: {
              threshold: effectValueConfig.sideThreshold,
              ratio: effectValueConfig.sideRatio,
              attack: effectValueConfig.sideAttack,
              release: effectValueConfig.sideRelease,
              knee: effectValueConfig.sideKnee,
            },
          }).toDestination();
          break;

        default:
          console.log("Unknown player type:", effectType);
          break;
      }

      if (turnOnSwitch) {
		sourceNode.disconnect();
        Tone.connect(sourceNode, effectRef.current);
      } else {
		sourceNode.disconnect();
        Tone.connect(sourceNode, audioContext.destination);
      }

      // return () => {
      //     sourceNode.disconnect();
      // };
    }
  };

  const updateEffect = () => {
    // Fetch effect Tone
    switch (effectType) {
      case "delay":
        // Create a FeedbackDelay effect
        // effectRef.current.set({
        //     delayTime: effectValueConfig.Time,
        //     wet:  effectValueConfig.Wet,
        //     feedback: effectValueConfig.Feedback });
        const audioEffect = effectRef.current;
        audioEffect.delayTime.rampTo(effectValueConfig.Time, 0.1);
        audioEffect.wet.rampTo(effectValueConfig.Wet, 0.1);
        audioEffect.feedback.rampTo(effectValueConfig.Feedback, 0.1);
        break;
      case "pingPongDelay":
        // Create a pingPongDelay effect
        effectRef.current.delayTime.rampTo(effectValueConfig.Time, 0.1);
        effectRef.current.wet.rampTo(effectValueConfig.Wet, 0.1);
        effectRef.current.feedback.rampTo(effectValueConfig.Feedback, 0.1);
        break;
      case "distortion":
        // Create a distortion effect
        // rampTo is not working here
        effectRef.current.set({
          distortion: effectValueConfig.Distortion,
        });
        break;
      case "reverb":
        // Create a reverb effect
        effectRef.current.wet.rampTo(effectValueConfig.Wet, 0.1);

        effectRef.current.set({
          decay: effectValueConfig.decay,
          preDelay: effectValueConfig.preDelay,
        });
        break;
      case "flanger":
        // Create a flanger effect
        effectRef.current.frequency.rampTo(effectValueConfig.Frequency, 0.1);
        effectRef.current.feedback.rampTo(effectValueConfig.Feedback, 0.1);
        effectRef.current.wet.rampTo(effectValueConfig.Wet, 0.1);

        effectRef.current.set({
          delayTime: effectValueConfig.Time,
          depth: effectValueConfig.Depth,
        });

        break;
      case "quadrafuzz":
        effectRef.current.setGains(
          effectValueConfig.lowGain,
          effectValueConfig.midLowGain,
          effectValueConfig.midHighGain,
          effectValueConfig.highGain
        );
        break;
      case "dubdelay":
        const audioEffectDubDelay = effectRef.current;
        audioEffectDubDelay.delayTime.value = effectValueConfig.Time;
        audioEffectDubDelay.wet.value = effectValueConfig.Wet;
        audioEffectDubDelay.feedback.value = effectValueConfig.Feedback;

        // Assuming filter is connected to delay, access the filter through delay's output
        if (audioEffectDubDelay.filter) {
          audioEffectDubDelay.filter.frequency.value = effectValueConfig.Cutoff;
        }
        break;
      case "compressor":
        // Create a Compressor effect with specified parameters
        effectRef.current.threshold.rampTo(effectValueConfig.Threshold, 0.1);
        effectRef.current.ratio.rampTo(effectValueConfig.Ratio, 0.1);
        effectRef.current.attack.rampTo(effectValueConfig.Attack, 0.1);
        effectRef.current.release.rampTo(effectValueConfig.Release, 0.1);
        effectRef.current.set({

          knee: effectValueConfig.Knee,
        });
        break;
      case "gate":
        // Create a gate effect with specified parameters
        effectRef.current.set({
          threshold: effectValueConfig.Threshold,
          smoothing: effectValueConfig.Smoothing,
        });
        break;
      case "multibandcompressor":
        // Create a multiBandCompressor effect with specified parameters
        effectRef.current.set({
          low: {
            threshold: effectValueConfig.lowThreshold,
            ratio: effectValueConfig.lowRatio,
            attack: effectValueConfig.lowAttack,
            release: effectValueConfig.lowRelease,
          },
          mid: {
            threshold: effectValueConfig.midThreshold,
            ratio: effectValueConfig.midRatio,
            attack: effectValueConfig.midAttack,
            release: effectValueConfig.midRelease,
          },
          high: {
            threshold: effectValueConfig.highThreshold,
            ratio: effectValueConfig.highRatio,
            attack: effectValueConfig.highAttack,
            release: effectValueConfig.highRelease,
          },
          lowFrequency: effectValueConfig.lowFrequency,
          highFrequency: effectValueConfig.highFrequency,
        });
        break;
      case "limiter":
        // smooth ramp limiter threshold and avoid infinite ramp when value is 0
        effectRef.current.threshold.rampTo((effectValueConfig.Threshold === 0) ? -.001 : effectValueConfig.Threshold, .1);
        break;
      case "bitcrusher":
        const audioEffectbitcrusher = effectRef.current;
        audioEffectbitcrusher.bits = effectValueConfig.bits || 4;
        audioEffectbitcrusher.wet.rampTo(effectValueConfig.wet || 0.5, 0.1);
        break;
      case "phaser":
        // Create a Phaser effect with specified parameters
        effectRef.current.frequency.rampTo(effectValueConfig.Frequency, 0.1);
        effectRef.current.Q.rampTo(effectValueConfig.Q, 0.1);
        
        // Set octaves and stages directly as they don't support rampTo
        effectRef.current.set({
          octaves: effectValueConfig.Octaves,
          stages: effectValueConfig.Stages,
          baseFrequency: effectValueConfig.BaseFrequency
        });
        break;
      case "pitchShift":
        // Update PitchShift effect parameters
        const audioEffectPitchShift = effectRef.current;
        audioEffectPitchShift._pitch = effectValueConfig.Pitch;
        audioEffectPitchShift._windowSize = effectValueConfig.WindowSize;
        audioEffectPitchShift.delayTime.rampTo(effectValueConfig.DelayTime, 0.5);
        audioEffectPitchShift.feedback.rampTo(effectValueConfig.Feedback, 0.5);
        break;
      case "stereowidener":
        // Update stereo widener effect parameters
        effectRef.current.set({
          wet: effectValueConfig.Wet,
          width: effectValueConfig.Width,
        });
        break;
      case "midsidecompressor":
        effectRef.current.set({
          mid: {
            threshold: effectValueConfig.midThreshold,
            ratio: effectValueConfig.midRatio,
            attack: effectValueConfig.midAttack,
            release: effectValueConfig.midRelease,
            knee: effectValueConfig.midKnee,
          },
          side: {
            threshold: effectValueConfig.sideThreshold,
            ratio: effectValueConfig.sideRatio,
            attack: effectValueConfig.sideAttack,
            release: effectValueConfig.sideRelease,
            knee: effectValueConfig.sideKnee,
          },
        });
        break;
      default:
        console.log("Unknown player type:", effectType);
        break;
    }
    // console.log(effectRef.current);
  };
  //Load Effect and Connect with AudioContext
  useEffect(() => {
    console.log("load effect");

    if (reloadEffect) {
      loadEffect();
    }
  }, [reloadEffect, turnOnSwitch]);

  useEffect(() => {
    if (effectConfig) {
      const objKeys = Object.keys(effectConfig);
      objKeys.forEach((key) => {
        setEffectValueConfig((prev) => ({
          ...prev,
          [key]: effectConfig[key].value,
        }));
      });
    }
  }, [effectConfig]);

  const createQuadrafuzz = (lowGain, midLowGain, midHighGain, highGain) => {
    const input = new Tone.Gain();
    const output = new Tone.Gain();

    const lowBand = new Tone.Filter({ frequency: 200, type: "lowpass" });
    const midLowBand = new Tone.Filter({ frequency: 1000, type: "bandpass" });
    const midHighBand = new Tone.Filter({ frequency: 3000, type: "bandpass" });
    const highBand = new Tone.Filter({ frequency: 8000, type: "highpass" });

    const lowDistortion = new Tone.Distortion(lowGain);
    const midLowDistortion = new Tone.Distortion(midLowGain);
    const midHighDistortion = new Tone.Distortion(midHighGain);
    const highDistortion = new Tone.Distortion(highGain);

    lowBand.connect(lowDistortion);
    midLowBand.connect(midLowDistortion);
    midHighBand.connect(midHighDistortion);
    highBand.connect(highDistortion);

    lowDistortion.connect(output);
    midLowDistortion.connect(output);
    midHighDistortion.connect(output);
    highDistortion.connect(output);

    input.fan(lowBand, midLowBand, midHighBand, highBand);
    output.toDestination();

    return {
      input,
      output,
      setGains: (low, midLow, midHigh, high) => {
        lowDistortion.distortion = low;
        midLowDistortion.distortion = midLow;
        midHighDistortion.distortion = midHigh;
        highDistortion.distortion = high;
      },
    };
  };

  return (
    <div className="flex flex-wrap md:flex-row gap-2 md:gap-10 justify-center">
      {effectConfig ? (
        Object.entries(effectConfig).map(([effectName, config]) => (
          <EffectController
            key={effectName}
            params={config}
            handleRangeChange={handleRangeChange}
            effectName={effectName}
          />
        ))
      ) : (
        // Render some placeholder or loading state if typeConfig is null/undefined
        <p>Loading effects configuration...</p>
      )}
    </div>
  );
}

const throttledHandleRangeChange = throttle((effectName, newValue, handleRangeChange) => {
  handleRangeChange(effectName, newValue);
}, 100);

export const EffectController = ({ params, handleRangeChange, effectName }) => {
  const [value, setValue] = useState(params.value || 0);
  const onChange = (value) => {
    const newValue = value;
    setValue(newValue);
    throttledHandleRangeChange(effectName, newValue, handleRangeChange);
  };

  return (
    <div className="flex p-2">
      <Dial
        label={effectName}
        size={80}
        degrees={260}
        min={params.min ?? 0}
        max={params.max ?? 100}
        step={params.step ?? 1}
        value={value ?? 0}
        color={true}
        onChange={onChange}
      />
    </div>
  );
};
